<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="渠道名称">
            <el-input v-model="queryForm.name" clearable placeholder="请输入渠道名称" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="渠道名称" prop="name" show-overflow-tooltip />
      <el-table-column label="类型">
        <template #default="{ row }">
          <el-tag type="success">{{ handleType(row.relationType) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="relationName" show-overflow-tooltip />
      <el-table-column label="访问数量" prop="pv" show-overflow-tooltip />
      <el-table-column label="报名数量" prop="attendNum" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="420">
        <template #default="{ row }">
          <el-button type="primary" @click="seeDetail(row, $event)">
            查看数据
          </el-button>
          <el-button type="success" @click="visitorExport(row, $event)" v-if="hostFlag == 'HOST'">
            数据导出
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    <edit ref="editRef" :event-id="queryForm.meetingCode" :meet-type="type" @fetch-data="fetchData" />

    <upload-file ref="upLoadRef" />

    <upload-result ref="resultRef" />
    <generateWxVue ref="generateWxRef"></generateWxVue>
    <export-register ref="exportRegisterRef"></export-register>
    <shareChannelDetail ref="channelDetailRef"></shareChannelDetail>
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getUserRelayQuery, relayExport } from '@/api/channel'

import handleClipboard from '@/utils/clipboard'
import generateWxVue from '@/components/generateWx/generateWx.vue'
import exportRegister from './components/exportRegister.vue'
import shareChannelDetail from './components/shareChannelDetail.vue'
import { exhibitionQueryPermissions } from '@/api/exhibitionManage'
export default defineComponent({
  name: 'ChannelManagar',
  components: {
    Edit: defineAsyncComponent(() => import('./components/channelEdit')),
    uploadFile: defineAsyncComponent(() =>
      import('./components/visitorImport')
    ),
    uploadResult: defineAsyncComponent(() =>
      import('./components/uploadResult')
    ),
    generateWxVue,
    exportRegister,
    shareChannelDetail,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        name: '',
        pageNum: 1,
        pageSize: 20,
        meetingCode: '',
      },
      type: '',
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      channelDetailRef: null,
      hostFlag: 'HOST',
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
    })

    const handleEdit = (row) => {
      console.log(row)
      if (row.eventId) {
        state.editRef.showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delCoupon(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const copy = (row, event) => {
      handleClipboard(row.channelUrl, event)
    }
    const downLoad = (row) => {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      var _this = this
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/jpg') //得到图片的base64编码数据
        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = '渠道二维码' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = row.channelQrcodeUrl
    }
    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getUserRelayQuery(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }

    onActivated(() => {
      console.log(proxy.$route)
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      state.type = proxy.$route.query.type || 'EXHIBITION'
      queryData()
      getPermissionCustomized( { code: state.queryForm.meetingCode})
    })

    // 查询导出结果
    const exportResult = (row) => {
      console.log(state.resultRef, row)
      state.resultRef.showEdit(row)
    }

    const visitorExport = (row) => {
      console.log(row)
      const params = {
        code: state.queryForm.meetingCode,
        channelNo: row.relayCode,
        relationType: state.type,
      }
      state.exportRegisterRef.handleOpen(
        params,
        `分享渠道`,
        `USER_RELAY_CHANNEL_REGISTER_REPORT`
      )
      // relayExport(row.relayCode).then((res) => {
      //   console.log(res)
      //   if (!res.data.success) {
      //     proxy.$baseMessage(
      //       `当前数据为空，无法导出`,
      //       'error',
      //       'vab-hey-message-error'
      //     )
      //   } else {
      //     window.open(res.data.fileUrl)
      //   }
      // })
    }

    const handleType = (type) => {
      let keyMap = [
        {
          value: '应用主页',
          key: 'EXHIBITION',
        },
        {
          value: '机构',
          key: 'EXHIBITOR',
        },
        {
          value: '产品',
          key: 'EXHIBIT',
        },
        {
          value: '会议',
          key: 'HOLD_MEETING',
        },
      ]
      let value = keyMap.filter((v) => v.key == type)
      if (value.length > 0) {
        return value[0].value
      } else {
        return `无类型`
      }
    }

    // 查看数据
    const seeDetail = (row) => {
      state.channelDetailRef.handleOpen(row, state.type)
    }



    const getPermissionCustomized = async (row) => {
      const { data } = await exhibitionQueryPermissions({
        meetingCode: row.code,
        meetingType: state.type,
      })

      state.hostFlag = data
    }
    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      copy,
      downLoad,
      visitorExport,
      exportResult,
      Delete,
      Search,
      Plus,
      handleType,
      seeDetail,
      getPermissionCustomized
    }
  },
})
</script>
<style lang="scss" scoped></style>
